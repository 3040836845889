import { render, staticRenderFns } from "./ShowSimpleContent.vue?vue&type=template&id=4e298c12"
import script from "./ShowSimpleContent.vue?vue&type=script&lang=js"
export * from "./ShowSimpleContent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports